.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App-Width{
  width: 90%;
  margin: auto;
}

label{
  margin-left: 10px;
}

.large{
  font-size: 24px;
}

.medium{
  font-size: 20px;
}

.small{
  font-size: 8px;
}

.horizontal-margin{
  margin-left: 10px;
  margin-right: 10px;
}

.right-margin{
  margin-right: 10px;
}

.left-margin{
  margin-left: 10px;
}

.x3Left-margin{
  margin-left: 30px;
}

.card-header{
  font-size: 20px;
}

.badge-dark{
  background-color: #343a40;
}

.badge-info{
  background-color: #2891b1 !important;
}

.badge-secondary{
  background-color: #6c757d !important;
}

.card-title{
  font-size: 24px;
}

.card{
  margin-top: 10px;
}

.input-within-card{
  margin-left: 10px;
  width: 99% !important;
}

.top-margin{
  margin-top: 10px;
}

.k-number-badge{
  width: 3rem;
}

.location-type-badge{
  width: 4rem;
}

h1{
  text-align: center;
}

h2{
  margin-left: 10px;
  margin-top: 10px !important;
}

card{
  margin-top: 10px !important;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
